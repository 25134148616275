import type { Lesson } from './content-definitions';
import type { AnswerAndData } from './answer-and-data';

export interface CheckedAnswer {
  correct: boolean | undefined; // true, false, or undefined if just meant to be warned
  warning?: string | undefined;
  newQuestion?: boolean; // used for muti-part questions
}

export interface WeightedAnswerAndData extends AnswerAndData {
  questionNumber: number;
  section: number;
  /** in milliseconds */
  answerSpeed: number;
  weightedRightAnswers: number;
  weightedWrongAnswers: number;
  weightedTotalQuestions: number;
}

export interface CustomCheckpointSection {
  options: {
    disabled?: boolean;
    /** To override default section time limit */
    timeLimit?: string | null;
    /** If a specific passing percent is required for this section */
    passingPercent?: number | null;
    /** The weight of this section for grading purposes */
    weight: number | null;
  };
  questionGroups: CustomCheckpointQuestionGroup[];
}

export interface CustomCheckpointQuestionGroup {
  options?: {
    disabled?: boolean;
    /**
     * When present, this number of questions is asked, proportionally to the default
     * number of question prescribed for each questionBank within the group.
     */
    numberOfQuestions?: number | null;
  };
}

export interface SavedCustomCheckpoint extends CustomCheckpoint {
  id: string;
}

export type MergedCustomCheckpoint = Omit<
  SavedCustomCheckpoint & CheckpointTest,
  'model' | 'order'
>;

export interface CustomCheckpoint {
  id: string;
  /** the id of the customCheckpoint it extends */
  extendsId?: string;
  /** The CustomCheckpoint record for the model it extends, merged via server */
  model?: CheckpointTest;
  options: CheckpointOptions;
  sections: CustomCheckpointSection[];
  /**
   * Overrides the default overall score text that would normally be displayed
   * on completion of a custom test.
   */
  overallScoreText?: ScoreText[];
  /**
   * Overrides the default score text that would normally be displayed in
   * section results depending on the score a user has obtained in each
   * section. Omitting results in default text (not overallScoreText).
   */
  sectionScoreText?: ScoreText[];
  /** Text shown on uTheory landing page when a user follows the link for a test invite */
  testInviteText?: string;
  /** trailing part of url e.g., /oberlin-placement for a test invite url */
  testInviteLink?: string;
  /** joined by server in merge */
  inviteUrl?: string;
  /** When true, skill progress from the checkpoint is also saved */
  saveSkillProgress?: boolean;
  /** Array of user ids, only present on customCheckpoints */
  ownerIds: string[];
  /** Array of teacher ids, only present on customCheckpoints */
  teacherIds: string[];
  /** Array of teaching assistant ids only present on customCheckpoints */
  teachingAssistantIds: string[];
  customCheckpoint: true;
  /** If true, shows as a main menu tab in uTheory */
  hasTab?: boolean;
  /** Title for menu bar tab */
  tabTitle?: string;
  /** Whether to display test instead of dashboard */
  landingPage: 'never' | 'incomplete' | 'passing' | 'always';
  /** Definition for what is passing in a custom checkpoint */
  passing?: Passing;
  /** Use the highest score or the most recent attempt for grading purposes (default: highest score) */
  attemptToUse: 'highest' | 'mostRecent';
  /** -1 for infinite; 0 or false for none */
  allowMultipleAttempts?: false | number;
  /** whether a password is required, and the password if one is required */
  password: false | string;
  /** whether a unique ID from another institution is required, i.e. an admissions software, etc... */
  requireExternalUniqueId?: boolean;
  /** ISO Date String */
  createdAt: Date;
  /** ISO Date String */
  updatedAt: Date;
}

export function isCustomCheckpoint(test: any): test is CustomCheckpoint {
  return typeof test === 'object' && 'customCheckpoint' in test;
}

export interface CheckpointOptions {
  /** plain text, display name for custom checkpoints */
  name: string;
  /**
   * HTML instructions for full test, the following handlebar variables are
   * available:
   * {{testName}} set by test.options.name
   * {{sections}} set by number of enabled sections
   * {{totalTime}} by test time or total of section times.
   * {{secondsPerQuestion}}
   * {{totalQuestions}}
   * {{passingPercent}} set by test.passing.passingPercent
   */
  instructions: string;
  /**
   * MM:SS format. Whether an overall timelimit is set on the test.
   * Generally this is omitted in favor of section time limits.
   */
  timeLimit?: string;
}

export interface QuestionGroup {
  options?: {
    name?: string;
    disabled?: boolean;
    /**
     * When present, this number of questions is asked, proportionally to the default
     * number of question prescribed for each questionBank within the group.
     */
    numberOfQuestions?: number;
  };
  questionBanks: QuestionBank[];
  questionTypes: QuestionType[];
}

export interface QuestionBank {
  numberOfQuestions: number;
  maxQuestions?: number;
  questions: any[];
}

/**
 * Text displayed in results depending on the score a user has
 * obtained.
 */
export interface ScoreText {
  /** The lowest percent that will be shown this text */
  lowerPercent: number;
  /**
   * Use the following handlebar variables as desired:
   * {score} {passingPercent} {title} {red}{/red} {green}{/green}
   */
  text: string;
}

export interface CheckpointSectionOptions {
  /**
   * HTML displayed prior to start of section , the following handlebar variables are
   * available:
   * {{testName}} set by test.options.name
   * {{sectionName}} set by section.options.name
   * {{sectionQuestions}} number of questions in this section
   * {{sectionPassingPercent}} set by test.section.options.passingPercent or test.passing.passingPercent
   * {{sectionTime}} time limit in MM:SS for this section
   * {{secondsPerQuestion}}
   * {{sections}} set by number of enabled sections
   * {{sectionNumber}} the number of the current section
   * {{totalQuestions}}
   * {{passingPercent}} set by test.passing.passingPercent
   *
   */
  instructions: string;
  /** Name of section shown in results, etc... */
  name: string;
  /** Whether the section is disabled in a custom checkpoint */
  disabled?: boolean;
  /** String as MM:SS for section duration */
  timeLimit: string;
  /** How to sort questions within the section, by question type, by question group, or randomly */
  sortOrder:
    | 'type'
    | 'typeIgnoreOptions'
    | 'group'
    | 'random'
    | 'typeDsc'
    | 'typeIgnoreOptionsDsc'
    | 'groupDsc'
    | 'randomDsc';
  /** Relative weight of this section in the test, as a percentage */
  weight: number;
  /** If this section completes lessons, an array of which lessons it completes. NOT YET IMPLEMENTED */
  completesLessons?: string[];
  /** If this section completes skills, a list of those skills NOT YET IMPLEMENTED */
  completesSkills?: string[];
  /**
   * If each section must be passed, the passing percent for this section. Otherwise
   * defaults to full test passing percent.
   */
  passingPercent?: number;
}

export interface CheckpointSection {
  options: CheckpointSectionOptions;
  questionGroups: QuestionGroup[];
}

export interface QuestionType {
  /** Interactive name, such as NoteID */
  questionType: string;
  /** weighting of frequency among question types */
  questionTypeFrequency?: number;
  /** weighting of each question of this question type */
  questionWeighting: number;
  /** Options for the particular question type passed to the interactive */
  options?: Record<string, any>;
  /**
   * If this is the only group in this section, disable this question type -- for instance,
   * if only Dominant 7ths selected in RN's, we don't want to use RN Identification, as the
   * answer will be all the same.
   */
  excludeIfOnlyQuestionGroup?: boolean;
}

export interface Passing {
  /** whether sections, the whole test or nothing has to be passed */
  type: 'sections' | 'overall' | 'both' | null;
  /** the percentage required for the above passing metrics */
  passingPercent: number;
}

export interface LessonCheckpointTest extends Omit<CheckpointTest, 'isPublic'> {
  areaId: 'rhythm' | 'pitch_and_harmony' | 'ear_training';
  order: number;
  id: string;
}

export interface CheckpointTest {
  options: CheckpointOptions;
  isPublic: boolean;
  /** -1 for infinite; 0 or false for none; only functions on customCheckpoints */
  allowMultipleAttempts?: false | number;
  /** Appears when in lesson yaml file */
  areaId?: 'rhythm' | 'pitch_and_harmony' | 'ear_training' | null;
  /** Order, if it appears in a lesson list */
  order?: number;
  /** Appears when in lesson yaml file */
  checkpoint?: boolean;
  /**
   * If true, will mark lessons completed based on how a student
   * does on various sections. NOT YET IMPLEMENTED
   */
  completesLessons?: boolean;
  /** If true, can appear in tabs, etc... */
  customCheckpoint?: boolean;
  /** If true, all questions will be delivered */
  debugMode?: boolean;
  /** If true, shows as a main menu tab in uTheory */
  hasTab?: boolean;
  /** Title for menu bar tab */
  tabTitle?: string;
  /** Title displayed in lessons list, if checkpoint is in lesson list; otherwise this doesn't matter */
  title?: string;
  /** database id, only present for custom checkpoints */
  id: string;
  /** Whether to display test instead of dashboard */
  landingPage?: 'incomplete' | 'passing' | 'always' | 'never';
  /** Array of user ids, only present on customCheckpoints -- the presence of 'public' allows this to be discovered by anyone */
  ownerIds?: string[];
  /** Present on customCheckpoints */
  passing?: Passing;
  /** Present on customCheckpoints. Use the highest score or the most recent attempt for grading purposes (default: highest score) */
  attemptToUse?: 'highest' | 'mostRecent';
  /** When true, skill progress from the checkpoint is also saved */
  saveSkillProgress?: boolean;
  /** Each section of the checkpoint test */
  sections: CheckpointSection[];
  /** Text shown on uTheory landing page when a user follows the link for a test invite */
  testInviteText?: string;
  /**
   * Questionbanks to be shared throughout the test. In test questions the
   * {{questionBankName}} is replaced with one of the questions -- and these
   * are optimized using question generator's reject duplicates to ensure
   * maximum variety and minimum repetition
   */
  globalQuestionBanks?: {
    [questionBankName: string]: (string | number)[];
  };
}

/**
 * The results of a checkpoint prior to be saving to the database
 */
export interface CheckpointStats {
  checkpointName: string;
  customCheckpoint: boolean;
  sections: SectionStats[];
  /** required passing percent */
  passing?: Passing;
  overall: {
    rightAnswers: number;
    wrongAnswers: number;
    weightedRightAnswers: number;
    weightedWrongAnswers: number;
    weightedTotalQuestions: number;
    percentage: number;
    totalQuestions: number;
  };
  uuid: number;
  attemptStatus?: 'initialized' | 'inProgress' | 'complete';
}

/**
 * The results of a checkpoint as retrieved from the database
 */
export interface CheckpointResults extends CheckpointStats {
  id: string;
  userId: string;
  checkpointId: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  questions: WeightedAnswerAndData[];
}

export interface SectionStats {
  sectionName: string;
  totalQuestions: number;
  rightAnswers: number;
  weightedRightAnswers: number;
  wrongAnswers: number;
  weightedWrongAnswers: number;
  percentage: number;
  passingPercent: number | null;
  completesLessons: string[] | null;
  completesSkills: (string | number)[] | null;
}

export interface CheckpointQuestionLogRequest {
  answerAndData: WeightedAnswerAndData;
  stats: CheckpointStats;
}

// Used to distinguish checkpoints & lessons in lesson list.
export function isCheckpoint(
  lessonOrCheckpoint:
    | Lesson
    | CheckpointTest
    | Readonly<Lesson>
    | Readonly<LessonCheckpointTest>
    | undefined
): lessonOrCheckpoint is CheckpointTest {
  if (!lessonOrCheckpoint) return false;
  // eslint-disable-next-line @typescript-eslint/dot-notation
  if ((lessonOrCheckpoint as CheckpointTest)['checkpoint']) return true;

  // eslint-disable-next-line @typescript-eslint/dot-notation
  if ((lessonOrCheckpoint as CheckpointTest)['sections']) return true;
  return false;
}

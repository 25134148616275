import type { SectionSkillAssignment } from '@prisma/client';
import { action, computed, makeObservable, observable } from 'mobx';
import { Saveable } from '../../user/saveable';
import type {
  KnowledgeGraphEdge,
  KnowledgeGraphSkillNode,
} from '../../knowledge/knowledgeGraphDefinitions';
import type SectionStore from '../section.store';
import type SectionKnowledgeTopicStore from './section.knowledge.topic.store';
import type SectionKnowledgeAreaStore from './section.knowledge.area.store';
import type { SectionLessonOrCheckpointStore } from '../sectionLessons/section.lessonOrCheckpoint.store';
import { isLessonCheckpointDefinition } from '../sectionLessons/section.lessonOrCheckpoint.store';

export default class SectionKnowledgeSkillStore extends Saveable {
  private root!: SectionStore;

  id!: number;

  textId!: string;

  definition!: KnowledgeGraphSkillNode;

  type!: 'skill';

  @observable private _status: 'required' | 'hidden' | 'optional' | undefined;

  @computed get title(): string {
    if (typeof this.definition.title === 'string') return this.definition.title;
    const systems = this.root.options.systems;
    const solfegeMethod = systems.solfege.method;

    if (this.definition.title[solfegeMethod]) {
      return this.definition.title[solfegeMethod] as string;
    }

    // This should never happen
    return this.definition.title.scaleDegrees;
  }

  @computed get parents(): (
    | SectionKnowledgeSkillStore
    | SectionKnowledgeTopicStore
    | SectionKnowledgeAreaStore
  )[] {
    const isEdgeToMe = (e: KnowledgeGraphEdge): boolean => e.childId === this.definition.textId;
    const parentIds = this.root.knowledge.knowledgeGraphEdges
      .filter(isEdgeToMe)
      .map((e: KnowledgeGraphEdge) => e.parentId);

    const parents = (
      [] as (SectionKnowledgeSkillStore | SectionKnowledgeTopicStore | SectionKnowledgeAreaStore)[]
    )
      .concat(this.root.knowledge.skills)
      .concat(this.root.knowledge.topics)
      .concat(this.root.knowledge.areas)
      .filter(
        (
          nodeStore:
            | SectionKnowledgeSkillStore
            | SectionKnowledgeTopicStore
            | SectionKnowledgeAreaStore
        ) => parentIds.includes(nodeStore.textId)
      );

    return parents;
  }

  @computed get children(): SectionKnowledgeSkillStore[] {
    const isEdgeFromMe = (e: KnowledgeGraphEdge): boolean => e.parentId === this.definition.textId;
    const childIds = this.root.knowledge.knowledgeGraphEdges
      .filter(isEdgeFromMe)
      .map((e: KnowledgeGraphEdge) => e.childId);

    return this.root.knowledge.skills.filter((skill) => childIds.includes(skill.textId));
  }

  @computed get topic(): SectionKnowledgeTopicStore | undefined {
    return this.parents.find((parent) => parent.type === 'topic') as
      | SectionKnowledgeTopicStore
      | undefined;
  }

  @computed get area(): SectionKnowledgeAreaStore | undefined {
    return this.parents.find((parent) => parent.type === 'area') as
      | SectionKnowledgeAreaStore
      | undefined;
  }

  @computed get status(): 'required' | 'optional' | 'hidden' {
    return (
      this._status || (this.root.knowledge.skillsSettingsById[this.textId]?.[0]?.status ?? 'hidden')
    );
  }

  @computed
  get lmsStatus(): 'required' | 'optional' | 'hidden' {
    if (!this.isVisibleOnLMS) return 'hidden';
    if (this.isRequired) return 'required';
    return 'optional';
  }

  @computed
  get dueDate(): Date | undefined {
    if (!this.isRequired) return undefined;
    const dueDates = this.lessonsUsingThisSkill
      .map((lesson) => lesson.dueDate)
      .filter((dueDate): dueDate is Date => !!dueDate)
      .sort((a, b) => a.valueOf() - b.valueOf());
    if (!dueDates.length) return undefined;
    return dueDates[0];
  }

  /**
   * If visible deadlines are off, all required skills are included in grade to date.
   * If visible deadlines are on, and we're set to use a lesson skill, we use that deadline.
   * Otherwise, we include all required skill. (Even though isDue will return false.)
   */
  @computed
  get includeInGradeToDate(): boolean {
    if (!this.isRequired) return false;
    if (!this.root.options.deadlines.visible) return true;
    if (!this.root.options.knowledge.useLessonSkills) return true;
    return this.isDue;
  }

  /**
   * If the section uses lesson skills and the lesson have due dates, then
   * we use those due dates. Otherwise, skill due dates are not yet implemented
   * on the front end, so we ignore them, and mark everything as due now.
   */
  @computed
  get isDue(): boolean {
    if (!this.isRequired) return false;
    const useLessonSkills = this.root.options.knowledge.useLessonSkills;
    const visibleDueDates = this.root.options.deadlines.visible;
    if (!useLessonSkills || !visibleDueDates) return false;
    // find the earliest due lesson that requires this skill. If none requires
    // it, or it has no due date, mark this as due now.
    return !!this.dueDate && this.dueDate < this.root.now;
  }

  @computed private get lessonsUsingThisSkill(): SectionLessonOrCheckpointStore[] {
    return this.root.lessons.lessons.filter((lesson) => {
      if (isLessonCheckpointDefinition(lesson.definition)) return false;
      return lesson.definition.skills.includes(this.textId);
    });
  }

  @computed
  get isVisible(): boolean {
    if (this.root.options.knowledge.useLessonSkills) {
      // If hideSkillsNotInLessons hide it if it's not in a lesson, otherwise it's optional (and visible).
      return this.root.options.knowledge.hideSkillsNotInLessons
        ? this.root.lessons.visibleSkills.includes(this.textId)
        : true;
    }
    return !!this.area?.enabled && this.status !== 'hidden';
  }

  /**
   * Whether a lesson is visible on a connected LMS for the section. Optional lessons may
   * be set to be visible on uTheory but hidden in the LMS.
   */
  @computed get isVisibleOnLMS(): boolean {
    return this.isRequired || (this.isOptional && !this.root.options.lms.hideOptionalContent);
  }

  @computed
  get isRequired(): boolean {
    if (this.root.options.knowledge.useLessonSkills) {
      return this.root.lessons.requiredSkills.includes(this.textId);
    }
    return !!this.area?.enabled && this.status === 'required';
  }

  @computed
  get isOptional(): boolean {
    return this.isVisible && !this.isRequired;
  }

  constructor(definition: KnowledgeGraphSkillNode, root: SectionStore) {
    super();
    makeObservable(this);
    this.init(definition, root);
  }

  @action init(definition: KnowledgeGraphSkillNode, root: SectionStore): void {
    this.root = root;
    this.type = 'skill';
    this.definition = definition;
    this.id = this.definition.id;
    this.textId = this.definition.textId;
  }

  @action setStatus(status: 'required' | 'optional' | 'hidden') {
    this._status = status;
  }

  @action save() {
    console.log('save!');
    const clientUserStore = this.root.root;
    if (!clientUserStore) {
      return Promise.reject('Save only available when clientUserStore is at root.');
    }

    return this.saveOrQueue(() =>
      clientUserStore?.api.ApiQueue.queue(
        () =>
          clientUserStore.api.$put<SectionSkillAssignment>(
            `api/sections/${this.root.id}/skills/${this.textId}`,
            {
              status: this.status,
            }
          ),
        { maxRetries: 3 }
      )
    ).then((skillAssignment) => {
      this.setStatus(skillAssignment.status);
      return skillAssignment;
    });
  }
}
